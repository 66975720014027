import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  dashboardPage: {},
  logo: {
    width: 180,
  },
  layout: {
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.90) 27.49%, rgba(255, 255, 255, 0.00) 65%)',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      overflow: 'scroll',
      justifyContent: 'space-between',
      paddingBottom: '30px',
      paddingTop: '20px',
      height: '90vh',
    },
  },
  layoutDark: {
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    background:
      'linear-gradient(180deg, #212121 10%, rgba(33, 33, 33, 0.40) 100%)',
    // 'linear-gradient(180deg, rgba(255, 255, 255, 0.90) 76%, rgba(255, 255, 255, 0.00) 100%)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      overflow: 'scroll',
      justifyContent: 'space-between',
      paddingBottom: '30px',
      paddingTop: '20px',
      height: '90vh',
    },
  },
  content: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentProfile: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid rgba(0, 0, 0, 0.10)',
    borderImage:
      'linear-gradient(to bottom,  rgba(102, 102, 102, 0.2), rgba(0, 0, 0, 0.0)) 1 100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'fit-content',
      justifyContent: 'start',
      paddingLeft: '10px',
    },
  },
}));
