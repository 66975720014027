import { gql } from 'apollo-boost';

export const CREATE_SIGNATURE = gql`
  mutation createSignature($input: SigningProcessInput!) {
    createSignature(input: $input) {
      signingProcess {
        id
        signingFile
        isBiometricAvailable
        isContract
        clients {
          edges {
            node {
              id
              user {
                username
                firstName
                lastName
              }
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const SIGN_DOCUMENT = gql`
  mutation signDocument($id: ID!) {
    signDocument(signingDocumentId: $id) {
      signingDocument {
        id
        pdfSheet
        signedPdfSheet
        createdAt
        updatedAt
        dsSigningUrl
        dsUrls
        signingProcess {
          id
          clients {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
          keynuaprocessSet {
            edges {
              node {
                id
                contractId
                client {
                  id
                }
                externalSigner {
                  id
                }
                token
                state
                webhookMessage
              }
            }
          }
        }
      }
    }
  }
`;

export const DOWNLOAD_DOCUMENT = gql`
  mutation downloadDocument($id: ID!) {
    downloadDocument(signingDocumentId: $id) {
      signingDocument {
        id
        pdfSheet
        signedPdfSheet
        createdAt
        updatedAt
        dsSigningUrl
        dsUrls
        signingProcess {
          id
          clients {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SIGNATURE = gql`
  mutation updateSignature($id: ID!, $input: SigningProcessInput!) {
    updateSignature(id: $id, input: $input) {
      signingDocument {
        id
        pdfSheet
        signedPdfSheet
        createdAt
        updatedAt
        dsSigningUrl
        dsUrls
        signingProcess {
          id
          signingFile
          isBiometricAvailable
          isContract
          clients {
            edges {
              node {
                id
                user {
                  username
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
      keynuaProcess {
        id
        contractId
        client {
          id
        }
        externalSigner {
          id
        }
        token
        state
        webhookMessage
      }
    }
  }
`;

export const DELETE_SIGNATURE = gql`
  mutation deleteSignature {
    deleteSignature {
      signature
    }
  }
`;

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      username
      userType
      id
      firstName
      lastName
      email
      frontendPermissions {
        edges {
          node {
            id
            displayName
            slug
          }
        }
      }
      promoter {
        id
        firstName
        lastName
        promoterCompanies {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const LIST_SIGNATURES = gql`
  query listSignatures {
    listSigningDocuments {
      edges {
        node {
          id
          createdAt
          pdfSheet
          signedPdfSheet
          signedPdfStatus
          dsUrls
          signingProcess {
            id
            isBiometricAvailable
            isContract
            subject
            body
            isDefaultWording
            remuneraciones
            ahorro
            utilidades
            alquileres
            ventaAcciones
            ventaBienesInmuebles
            ventaBienesMuebles
            herencias
            prestamos
            otros
            otrosTexto
            explicacion
            clients {
              edges {
                node {
                  id
                  address {
                    detail
                    country {
                      name
                    }
                    county {
                      name
                    }
                    city {
                      name
                    }
                    district {
                      name
                    }
                  }
                  user {
                    id
                    username
                    firstName
                    lastName
                    email
                  }
                }
              }
            }
            externalSigners {
              edges {
                node {
                  id
                  firstName
                  lastName
                  documentNumber
                  email
                }
              }
            }
            keynuaprocessSet {
              edges {
                node {
                  id
                  contractId
                  client {
                    id
                  }
                  externalSigner {
                    id
                  }
                  token
                  state
                  webhookMessage
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_EXTERNAL_SIGNERS = gql`
  query listExternalSigners {
    listExternalSigners {
      edges {
        node {
          id
          firstName
          lastName
          documentNumber
          email
          token
        }
      }
    }
  }
`;
