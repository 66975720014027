import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';

import SimpleForm from '../../../Forms/SimpleForm';
import {
  GET_INTERESTED_INVESTOR_INFO,
  LIST_INTERESTED_INVESTORS,
  CREATE_USER_INTERESTED_INVESTORS,
  UPDATE_USER_INTERESTED_INVESTORS,
  UDPATE_CLIENT_INTERESTED_INVESTORS,
  DELETE_USER_INTERESTED_INVESTORS,
  LIST_PROMOTERS,
  LIST_LEADS_CHANNELS,
  LIST_LEADS_CHANNELS_DETAIL,
  LIST_LEADS_RANGES,
  LIST_LEAD_PRODUCTS,
  LIST_CANAL_ACTUAL,
  LIST_CANAL_ORIGEN,
} from '../queries';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_DOCUMENT_TYPES } from '../../DocumentType/queries';
import { formStructure, validationSchema, startData } from './formInfo';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import { LIST_PROMOTER_COMPANIES } from '../../PromoterComponent/queries';
import { getPromoterSelector } from '../../../../store/sharedStore/selectors';
import { parseEquivalent } from '../../../../utils/commonFunctions';
import Yup from '../../../../utils/yup';

export const BUSINESS_PROMOTER = 'businessPromoter';

const userTypes = {
  PN: { id: 'PERSONA_NATURAL', name: 'Persona natural' },
  PJ: { id: 'PERSONA_JURIDICA', name: 'Persona jurídica' },
  AU: { id: 'PERSONA_AUTONOMA', name: 'Persona autonoma' },
};

function InterestedInvestorForm(props) {
  const { id } = useParams();
  const currentPromoter = useSelector(getPromoterSelector());
  const isEdit = id !== 'crear';
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [investorData, setInvestorData] = useState(null);
  const [currentCoreConsultant, setCoreConsultant] = useState('');
  const [currentPromoterSabbi, setPromoterSabbi] = useState('');
  const [isOnlyCore, setIsOnlyCore] = useState('');
  const [initData, setInitData] = useState(startData);
  const [promoters, setPromoters] = useState([]);
  const [corePromoters, setCorePromoters] = useState([]);
  const [saveClientData, setSaveClientData] = useState({});

  // RELATED QUERIES
  const [loadInvestor, { called, loading, data: InvestorData }] = useLazyQuery(
    GET_INTERESTED_INVESTOR_INFO,
    {
      variables: {
        id,
      },
    },
  );
  const {
    loading: promoterCompaniesLoading,
    error: promoterCompaniesError,
    data: promoterCompaniesData,
  } = useQuery(LIST_PROMOTER_COMPANIES);
  const {
    loading: promotersLoading,
    error: promotersError,
    data: promotersData,
  } = useQuery(LIST_PROMOTERS);
  const {
    loading: documentTypesLoading,
    error: documentTypesError,
    data: documentTypesData,
  } = useQuery(LIST_DOCUMENT_TYPES);
  const {
    loading: LeadsChannelsLoading,
    error: LeadsChannelsError,
    data: LeadsChannelsData,
  } = useQuery(LIST_LEADS_CHANNELS);
  const {
    loading: LeadsChannelsDetailLoading,
    error: LeadsChannelsDetailError,
    data: LeadsChannelsDetailData,
  } = useQuery(LIST_LEADS_CHANNELS_DETAIL);
  const {
    loading: LeadsRangesLoading,
    error: LeadsRangesError,
    data: LeadsRangesData,
  } = useQuery(LIST_LEADS_RANGES);
  const {
    loading: LeadProductsLoading,
    error: LeadProductsError,
    data: LeadProductsData,
  } = useQuery(LIST_LEAD_PRODUCTS);

  const { data: canalActualData } = useQuery(LIST_CANAL_ACTUAL);
  const { data: canalOrigenData } = useQuery(LIST_CANAL_ORIGEN);

  if (promoterCompaniesError) {
    console.error(
      'Interested Investor - list promoter companies',
      promoterCompaniesError,
    );
  }
  if (promotersError) {
    console.error('Interested Investor - list promoters', promotersError);
  }
  if (documentTypesError) {
    console.error(
      'Interested Investor - list document types',
      documentTypesError,
    );
  }
  if (LeadProductsError) {
    console.error('Productos de interes', LeadProductsError);
  }

  function promoterEdgeToList(promotersList) {
    if (promotersList) {
      return promotersList.edges.map(element => {
        const newNode = {
          id: element.node.id,
          fullName: `${element.node.firstName} ${element.node.lastName}`,
          enabled: element.node.enabled,
        };
        return newNode;
      });
    }
    return [];
  }

  // Client mutations
  const [updateClientMutation, { loading: loadingUpdate }] = useMutation(
    UDPATE_CLIENT_INTERESTED_INVESTORS,
    getOptions({
      mutationName: 'updateClient',
      modelName: 'client',
      message: investorData
        ? 'Inversionista interesado actualizado.'
        : 'Inversionista interesado creado.',
      refetchQueries: [
        {
          query: LIST_INTERESTED_INVESTORS,
          variables: {
            search: null,
            first: 10,
            afterCursor: null,
            last: null,
            beforeCursor: null,
          },
        },
      ],
      completeCallback: () => history.goBack(),
      enqueueSnackbar,
    }),
  );

  // User mutations
  const updateMutation = useMutation(
    UPDATE_USER_INTERESTED_INVESTORS,
    getOptions({
      mutationName: 'updateUser',
      modelName: 'user',
      enqueueSnackbar,
      completeCallback: (data, errors) => {
        updateClientMutation({
          variables: {
            id,
            input: saveClientData,
          },
        });
      },
    }),
  );

  const createMutation = useMutation(
    CREATE_USER_INTERESTED_INVESTORS,
    getOptions({
      mutationName: 'createUser',
      modelName: 'user',
      enqueueSnackbar,
      completeCallback: (data, errors) => {
        updateClientMutation({
          variables: {
            id: data.clientSet.edges[0].node.id,
            input: saveClientData,
          },
        });
      },
    }),
  );
  const deleteMutation = useMutation(
    DELETE_USER_INTERESTED_INVESTORS,
    getOptions({
      mutationName: 'deleteUser',
      modelName: 'user',
      message: 'Inversionista interesado eliminado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_INTERESTED_INVESTORS }],
      completeCallback: () => history.goBack(),
    }),
  );

  function promotersPerCompany({ target }) {
    if (promoterCompaniesData) {
      const company = edgeToList(
        promoterCompaniesData,
        'listPromoterCompany',
      ).find(companies => companies.id === target.value);
      if (company?.businessName?.split(' ')[0] == 'Core') {
        setIsOnlyCore(true);
      } else setIsOnlyCore(false);
      if (isEdit) {
        const complete = { ...initData };
        complete.promoter = '';
        complete.coreConsultant = '';
        setInitData(complete);
      }
      setPromoters(
        promoterEdgeToList(company.promoters).filter(p => p.enabled == true),
      );
    }
  }

  useEffect(() => {
    if (promotersData) {
      const promoters = edgeToList(promotersData, 'listPromoter')
        .filter(promoter => promoter.isCoreConsultant)
        .map(element => {
          const newNode = {
            id: element.id,
            fullName: `${element.firstName} ${element.lastName}`,
            enabled: element.enabled,
            isCoreConsultant: element.isCoreConsultant,
          };
          return newNode;
        });
      setCorePromoters(promoters);
    }

    if (currentPromoter) {
      const complete = { ...initData };
      if (currentCoreConsultant) {
        complete.coreConsultant = currentCoreConsultant;
      }
      if (currentPromoterSabbi) {
        complete.promoter = currentPromoterSabbi;
      }
      if (complete?.promoterCompany?.businessName?.split(' ')[0] == 'Core') {
        setIsOnlyCore(true);
      } else setIsOnlyCore(false);
      complete.promoterCompany =
        currentPromoter.promoterCompanies.edges[0].node.id;
      if (!promoterCompaniesLoading && promoterCompaniesData) {
        const company = edgeToList(
          promoterCompaniesData,
          'listPromoterCompany',
        ).find(companies => companies.id === complete.promoterCompany);
        setPromoters(
          promoterEdgeToList(company.promoters).filter(p => p.enabled == true),
        );
        setInitData(complete);
      }
    }
  }, [
    currentPromoter,
    currentCoreConsultant,
    promoterCompaniesData,
    promotersData,
  ]);

  useEffect(() => loadInvestor(), [id]);

  useEffect(() => {
    if (InvestorData && InvestorData.client) {
      let complete = { ...InvestorData.client };
      delete complete.user;
      complete = { ...complete, ...InvestorData.client.user };

      if (complete.promoterCompany) {
        if (complete?.promoterCompany?.businessName?.split(' ')[0] == 'Core') {
          setIsOnlyCore(true);
        } else setIsOnlyCore(false);
        complete.promoterCompany = complete.promoterCompany.id;
      }
      if (complete.coreConsultant) {
        setCoreConsultant(complete.coreConsultant.id);
        complete.coreConsultant = complete.coreConsultant.id;
      } else complete.coreConsultant = '';
      if (complete.promoter) {
        setPromoterSabbi(complete.promoter.id);
        complete.promoter = complete.promoter.id;
        if (promoterCompaniesData) {
          const company = edgeToList(
            promoterCompaniesData,
            'listPromoterCompany',
          ).find(e => e.id === complete.promoterCompany);
          const promotersList = promoterEdgeToList(company?.promoters).filter(
            p => p.enabled == true,
          );
          setPromoters(promotersList);
        }
      } else complete.promoter = '';
      complete.leadsProducts = complete.leadsProducts.edges.map(
        val => val.node.id,
      );
      if (complete.documentType && complete.documentType !== '') {
        complete.documentType = complete.documentType
          ? complete.documentType.id
          : startData.documentType;
      } else {
        complete.documentType = 'other';
      }
      if (complete.userType in userTypes) {
        const type = userTypes[complete.userType];
        complete.userType = type.id;
      } else {
        complete.userType = '';
      }
      if (complete.sex) {
        complete.sex = complete.sex;
      } else {
        complete.sex = '';
      }
      setInvestorData(complete);
    }
  }, [InvestorData]);

  function formatBeforeSubmit(values) {
    const { userType, ...newValues } = { ...values };

    newValues.firstName = newValues.firstName.trim();
    newValues.lastName = newValues.lastName.trim();
    newValues.maternalSurname = newValues.maternalSurname.trim();
    newValues.documentNumber = newValues.documentNumber.trim();
    newValues.sex = parseEquivalent(newValues.sex);

    const userValues = {};
    userValues.firstName = newValues.firstName;
    userValues.lastName = newValues.lastName;
    userValues.maternalSurname = newValues.maternalSurname;
    userValues.email = newValues.email;
    userValues.documentNumber = newValues.documentNumber;
    userValues.documentType = newValues.documentType;
    if (userType !== '') {
      userValues.userType = userType;
    }

    if (!isEdit) {
      userValues.isInterestedInvestor = true;
      userValues.isStaff = false;
    }
    if (newValues?.photoDocumentFront?.length < 30)
      delete newValues.photoDocumentFront;
    if (newValues?.photoDocumentBack?.length < 30)
      delete newValues.photoDocumentBack;
    delete newValues.photoDocumentsUrl;

    if (newValues.canalActual == '') newValues.canalActual = 'OT';
    if (newValues.canalOrigen == '') newValues.canalOrigen = 'OT';

    setSaveClientData(newValues);
    return userValues;
  }
  const validate = () => ({});

  const validationSchema = Yup.object().shape({
    promoterCompany: Yup.string().required(),
    documentType: Yup.string().required(),
    documentNumber: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    maternalSurname: Yup.string().required(),
    email: Yup.string()
      .email()
      .required(),
    cellphone: Yup.string(),
    leadsProducts: Yup.array().required(),
    userType: Yup.string().required(),
    leadsChannel: Yup.string().required(),
    leadsChannelDetail: Yup.string().required(),
    leadsRange: Yup.string().required(),
    sex: Yup.string().required(),
    promoter: Yup.string().when({
      is: () => !isOnlyCore,
      then: Yup.string().required(),
    }),
    coreConsultant: Yup.string().when({
      is: () => isOnlyCore,
      then: Yup.string().required(),
    }),
  });

  return loading && documentTypesLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <Grid container>
        <SimpleForm
          model={investorData}
          initialValues={initData}
          validateFunction={validate}
          validateSchema={validationSchema}
          formatBeforeSubmit={formatBeforeSubmit}
          data={formStructure(
            isEdit,
            isOnlyCore,
            edgeToList(promoterCompaniesData, 'listPromoterCompany'),
            promoters,
            corePromoters,
            edgeToList(documentTypesData, 'listDocumentTypes'),
            edgeToList(LeadProductsData, 'listLeadProducts'),
            promotersPerCompany,
            LeadsChannelsData ? LeadsChannelsData.leadsChannels : null,
            LeadsChannelsDetailData
              ? LeadsChannelsDetailData.leadsChannelsDetail
              : null,
            LeadsRangesData ? LeadsRangesData.leadsRanges : null,
            canalActualData ? canalActualData.canalesActual : null,
            canalOrigenData ? canalOrigenData.canalesOrigen : null,
          )}
          modelName="Inversionistas Interesados"
          hasCancel
          isEdit={isEdit}
          id={investorData && investorData.id}
          onCancel={() => history.goBack()}
          canBeDeleted
          updateMutation={updateMutation}
          createMutation={createMutation}
          deleteMutation={deleteMutation}
        />
      </Grid>
    </>
  );
}

export default InterestedInvestorForm;
