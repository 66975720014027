import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

const LISTBOX_PADDING = 8; // px

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const {
    children,
    isItemLoaded,
    loadMoreItems,
    itemCount,
    isLoadingMore,
    hasNextPage,
    firstRender,
    ...other
  } = props;

  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const itemSize = smUp ? 36 : 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const renderRow = props => {
    const { data, index, style } = props;

    if (!isItemLoaded(index)) {
      return null;
    }

    if (!data[index]) {
      return null;
    }

    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
      },
    });
  };

  let ofset = 0;
  if (!firstRender) {
    ofset = (itemCount / 1.5) * itemSize;
  }

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref: refList }) => (
            <FixedSizeList
              ref={refList}
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              key={itemCount}
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={itemSize}
              overscanCount={5}
              itemCount={itemCount}
              onItemsRendered={onItemsRendered}
              initialScrollOffset={ofset}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node.isRequired,
  isItemLoaded: PropTypes.func.isRequired,
  loadMoreItems: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  firstRender: PropTypes.bool.isRequired,
};

export default ListboxComponent;
