import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    fontWeight: 700,
    fontFamily: 'Montserrat',
    color: '#292D32',
    fontSize: '2rem',
    margin: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  content: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color: '#292D32',
    textAlign: 'center',
    fontSize: theme.typography.body1,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2,
    },
  },
  btnDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '10px',
    paddingTop: '10px',
  },
  btn: {
    backgroundColor: '#262626',
    borderRadius: '2px',
    cursor: 'pointer',
    padding: '8px',
    color: '#FFF',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#3b3b3b',
    },
  },
  btnMoreInfo: {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: '#262626',
  },

  moreInfoDiv: {
    display: 'flex',
    // width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
  },
  moreInfoDivProgress: {
    display: 'flex',
    // width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  divProgress: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'start',
    },
  },
  devTextProgress: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
