/*
 * HomePage
 */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

// Material Resources
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  Hidden,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Others
import clsx from 'clsx';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { delAuthUser } from '../../utils/storage';

// Components & Others
import AdminMenu from '../../components/AdminMenu';
import CustomTitle from '../../components/CustomTitle';
import NotFound from '../NotFoundPage/Loadable';
import DashboardPage from '../DashboardPage';
import SafiPage from '../ModelsPages/SafiPage/Loadable';
import FundPage from '../ModelsPages/FundPage';
import CurrencyPage from '../ModelsPages/CurrencyPage';
import CategoryPage from '../ModelsPages/CategoryPage';
import InstrumentPage from '../ModelsPages/InstrumentPage';
import FundSourcePage from '../ModelsPages/FundSourcePage';
import FundClassPage from '../ModelsPages/FundClassPage';
import { edgeToList } from '../../utils/commonFunctions';

// Styles & Images
import useStyles from './styles';
import BankPage from '../ModelsPages/BankPage';
import AccountTypePage from '../ModelsPages/AccountTypePage';
import BankAccountPage from '../ModelsPages/BankAccountPage';
import MaritalStatusPage from '../ModelsPages/MaritalStatusPage';
import DocumentTypePage from '../ModelsPages/DocumentTypePage';
import PropertyRegimePage from '../ModelsPages/PropertyRegimePage';
import MonthlyIncomeRangePage from '../ModelsPages/MonthlyIncomeRangePage';
import EducationLevelPage from '../ModelsPages/EducationLevelPage';
import SafiRelationPage from '../ModelsPages/SafiRelationPage';
import ClientPage from '../ModelsPages/UsersPages/ClientPage';
import AdministratorPage from '../ModelsPages/UsersPages/AdministratorPage';
import ScorePage from '../ModelsPages/ScorePage';
import PromoterCompanies from '../ModelsPages/PromoterCompanies';
import InterestedInvestorPage from '../ModelsPages/InterestedInvestorPage';
import PromoterPage from '../ModelsPages/PromoterPage';
import CompliancePage from '../ModelsPages/CompliancePage';
import VinculatedPage from '../ModelsPages/VinculatedPage';
import EditMenuPage from '../EditMenuPage';
import {
  setPermissions,
  setUserPermissions,
} from '../../store/permissionsStore/actions';
import { setPromoter } from '../../store/sharedStore/actions';
import PermissionsRoute from '../../components/PermissionsRoute';
import Profile from '../../components/Profile';
import ContractsPage from '../ModelsPages/ContractsPage';
import SwornDeclarationPage from '../ModelsPages/SwornDeclarationPage';
import SecondaryMarketPage from '../ModelsPages/SecondaryMarketPage';
import RejectedInvestorPage from '../ModelsPages/UsersPages/RejectedInvestorPage';
import ProcessPage from '../ModelsPages/ProcessPage';
import SignaturePage from '../ModelsPages/SignaturePage';
import FilesPage from '../ModelsPages/FilesPage';
import EmployeePage from '../ModelsPages/EmployeePage';
import EmployeeFilesPage from '../ModelsPages/EmployeeFilesPage';
import EmployeeFilesSpecificPage from '../ModelsPages/EmployeeFilesSpecificPage';
import EmployeeSignaturePage from '../ModelsPages/EemployeeSignaturePage';

const LIST_FRONTEND_SUBMODULES = gql`
  query listFrontendsubmodules {
    listFrontendSubmodules {
      edges {
        node {
          id
          slug
          displayName
        }
      }
    }
  }
`;

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      username
      id
      firstName
      lastName
      maternalSurname
      email
      frontendPermissions {
        edges {
          node {
            id
            displayName
            slug
          }
        }
      }
      promoter {
        id
        firstName
        lastName
        promoterCompanies {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

function AdminPage(props) {
  const { container } = props;
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const {
    loading: permissionsLoading,
    error: permissionsError,
    data: permissions,
  } = useQuery(LIST_FRONTEND_SUBMODULES);
  const {
    loading: currentUserLoading,
    error: currentUserError,
    data: currentUserData,
  } = useQuery(CURRENT_USER);

  if (!permissionsLoading && permissions) {
    dispatch(setPermissions(edgeToList(permissions, 'listFrontendSubmodules')));
  }
  if (permissionsError) {
    console.error('list submodules', permissionsError);
  }

  if (!currentUserLoading && currentUserData) {
    const { currentUser } = currentUserData;
    dispatch(
      setUserPermissions(edgeToList(currentUser, 'frontendPermissions')),
    );
    if (currentUser.promoter) {
      dispatch(setPromoter(currentUser.promoter, 'promoter'));
    }
  }
  if (currentUserError) {
    console.error('current user', currentUserError);
  }

  const handleClickUser = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUser = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    handleDrawerToggle();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleDrawerToggle();
  };
  const handleProfileUser = () => {
    history.push('/administrador/perfil');
    handleCloseUser();
  };
  const handleLogOut = () => {
    delAuthUser();
    history.push('/');
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.customToolbar} />
      <AdminMenu open={open} />
    </div>
  );

  return (
    <div className={classes.adminPage}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <div className={classes.toolbarItem}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
            >
              <MenuIcon color="secondary" />
            </IconButton>
            <CustomTitle />
          </div>
          <div className={classes.toolbarItem}>
            <Hidden xsDown>
              <p className={classes.welcome}>
                {currentUserData &&
                  `Hola ${currentUserData.currentUser.firstName}`}
              </p>
            </Hidden>
            <IconButton
              aria-controls="user icon button"
              aria-haspopup="true"
              onClick={handleClickUser}
            >
              <AccountCircleIcon color="secondary" fontSize="large" />
            </IconButton>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseUser}
          >
            <MenuItem onClick={handleProfileUser}>
              Cambio de contraseña
            </MenuItem>
            <MenuItem onClick={handleLogOut}>Salir</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerPaperBigScreen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>
          {currentUserData && (
            <Switch>
              <Route exact path={path} component={DashboardPage} />
              <PermissionsRoute path={`${path}/safis`} component={SafiPage} />
              <PermissionsRoute path={`${path}/fondos`} component={FundPage} />
              <PermissionsRoute
                path={`${path}/monedas`}
                component={CurrencyPage}
              />
              <PermissionsRoute
                path={`${path}/categorias`}
                component={CategoryPage}
              />
              <PermissionsRoute
                path={`${path}/instrumentos`}
                component={InstrumentPage}
              />
              <PermissionsRoute
                path={`${path}/fuentes`}
                component={FundSourcePage}
              />
              <PermissionsRoute
                path={`${path}/clases`}
                component={FundClassPage}
              />
              <PermissionsRoute path={`${path}/bancos`} component={BankPage} />
              <PermissionsRoute
                path={`${path}/empresas-promotoras`}
                component={PromoterCompanies}
              />
              <PermissionsRoute
                path={`${path}/tipos-de-cuenta`}
                component={AccountTypePage}
              />
              <PermissionsRoute
                path={`${path}/cuentas-bancarias`}
                component={BankAccountPage}
              />
              <PermissionsRoute
                path={`${path}/tipos-de-documento`}
                component={DocumentTypePage}
              />
              <PermissionsRoute
                path={`${path}/estados-civiles`}
                component={MaritalStatusPage}
              />
              <PermissionsRoute
                path={`${path}/regimenes-patrimoniales`}
                component={PropertyRegimePage}
              />
              <PermissionsRoute
                path={`${path}/ingresos-mensuales`}
                component={MonthlyIncomeRangePage}
              />
              <PermissionsRoute
                path={`${path}/niveles-de-instruccion`}
                component={EducationLevelPage}
              />
              <PermissionsRoute
                path={`${path}/relacion-con-la-safi`}
                component={SafiRelationPage}
              />
              <PermissionsRoute
                path={`${path}/administradores`}
                component={AdministratorPage}
              />
              <PermissionsRoute
                path={`${path}/clientes`}
                component={ClientPage}
              />
              <PermissionsRoute
                path={`${path}/inversionistas-interesados`}
                component={InterestedInvestorPage}
              />
              <PermissionsRoute
                path={`${path}/promotores`}
                component={PromoterPage}
              />
              <PermissionsRoute path={`${path}/score`} component={ScorePage} />
              <PermissionsRoute
                path={`${path}/editar-menu`}
                component={EditMenuPage}
              />
              <PermissionsRoute
                path={`${path}/tipos-de-contrato`}
                component={ContractsPage}
              />
              <PermissionsRoute
                path={`${path}/declaraciones-juradas`}
                component={SwornDeclarationPage}
              />
              <PermissionsRoute
                path={`${path}/mercado-secundario`}
                component={SecondaryMarketPage}
              />
              <PermissionsRoute
                path={`${path}/aprobacion-laft`}
                component={CompliancePage}
              />
              <PermissionsRoute
                path={`${path}/aprobacion-vinculados`}
                component={VinculatedPage}
              />
              <PermissionsRoute
                path={`${path}/inversionistas-desaprobados`}
                component={RejectedInvestorPage}
              />
              <PermissionsRoute
                path={`${path}/procesos`}
                component={ProcessPage}
              />
              <PermissionsRoute
                path={`${path}/firmados`}
                component={SignaturePage}
              />
              <PermissionsRoute
                path={`${path}/ficha-colaborador`}
                component={EmployeePage}
              />
              <PermissionsRoute
                path={`${path}/carga-colaborador`}
                component={EmployeeFilesPage}
              />
              <PermissionsRoute
                path={`${path}/carga-colaborador-especifico`}
                component={EmployeeFilesSpecificPage}
              />
              <PermissionsRoute
                path={`${path}/colaborador-procesos`}
                component={EmployeeSignaturePage}
              />
              <PermissionsRoute path={`${path}/carga`} component={FilesPage} />
              <Route path={`${path}/perfil`} component={Profile} />

              <PermissionsRoute path="" component={NotFound} />
            </Switch>
          )}
        </div>
      </main>
    </div>
  );
}

AdminPage.propTypes = {};

export default AdminPage;
