import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import Yup from '../../../../utils/yup';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import {
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  FormLabel,
} from '@material-ui/core';
import { Checkbox } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';

import SectionDivider from '../../../FormComponents/SectionDivider';
import {
  FILE_UPLOAD,
  LIST_TYPES_CHOICES,
  LIST_S3FILES_PAGINATED,
  LIST_CLASSIFICATION_CHOICES,
} from '../queries';
import { LIST_EMPLOYEES } from '../../Employee/queries';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import LoadingIndicator from '../../../LoadingIndicator';
import useStyles from './styles';

function ProcessFormFiles(props) {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [clientList, setClientList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileEncode, setFileEncode] = useState();
  const [fileName, setFileName] = useState('');
  const [limitGlobal, setLimitGlobal] = useState('');
  const [initialValues, setInitialValues] = useState({
    client: '',
    file: '',
    type: '',
    isGeneric: false,
  });
  const { loading: listClientLoading, error, data } = useQuery(LIST_EMPLOYEES);
  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_TYPES_CHOICES);
  const { data: ClassificationData } = useQuery(LIST_CLASSIFICATION_CHOICES);
  const [uploadFile, { loadingFile, dataFile }] = useMutation(
    FILE_UPLOAD,
    getOptions({
      enqueueSnackbar,
      refetchQueries: [
        {
          query: LIST_S3FILES_PAGINATED,
          variables: {
            search: null,
            classification: null,
            first: 10,
            afterCursor: null,
            last: null,
            beforeCursor: null,
          },
        },
      ],
    }),
  );

  useEffect(() => {
    if (!!data) {
      setClientList(getUsersByClients(edgeToList(data, 'listEmployees')));
    }
    if (!!TypesData) {
      setTypeList(TypesData.typesChoicesEmployee);
    }
    if (!!ClassificationData) {
      setClassificationList(ClassificationData.classificationChoices);
    }
  }, [data, TypesData, ClassificationData]);

  function getUsersByClients(clients) {
    clients.filter(element => !element.user.isStaff && element.user.isActive);
    return clients.map(element => {
      let { user } = element;
      user = {
        ...user,
        clientId: element.id,
        fullName: user.firstName + ' ' + user.lastName,
      };
      return user;
    });
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChange = async (e, propsForm) => {
    try {
      let name = e.target.files[0].name;
      let limit = e.target.files[0].size;
      const encoded = await toBase64(e.target.files[0]);
      propsForm.setFieldValue('file', encoded);
      setLimitGlobal(limit);
      setFileEncode(encoded);
      setFileName(name);
    } catch (error) {
      console.log('error', error);
    }
  };

  const validationSchema = Yup.object().shape({
    file: Yup.string().required(),
    client: Yup.string(),
    type: Yup.string().required(),
  });

  return listClientLoading ? (
    <LoadingIndicator />
  ) : (
    <div className={classes.simpleFormContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          uploadFile({
            variables: {
              input: {
                file: values.file,
                client: values.client.clientId,
                type: values.type,
                fileName: fileName,
                isGeneric: values.isGeneric,
                classification: values.classification,
              },
            },
          });
          history.goBack();
        }}
      >
        {propsForm => (
          <Form className={classes.simpleForm}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Cargar Archivo
                </Typography>
                <SectionDivider />
                <Grid container alignItems="center">
                  <>
                    <Grid className={classes.labelContainer} item xs={6}>
                      <InputLabel>Archivo</InputLabel>
                      {fileName && (
                        <div>
                          <small>{fileName}</small>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        component="label"
                        color="primary"
                        name="file"
                        error={propsForm.errors.file}
                        required
                      >
                        Buscar Archivo
                        <input
                          id="file"
                          name="file"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={e => handleChange(e, propsForm)}
                        />
                      </Button>
                      {limitGlobal / 1000000 > 100 && (
                        <div>
                          <small style={{ color: 'red' }}>
                            El límite es 100 MG, sumas {limitGlobal / 1000000}MG
                          </small>
                        </div>
                      )}
                      <ErrorMessage name="file">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      id="client"
                      fullWidth
                      disabled={propsForm.values.isGeneric}
                      value={propsForm.values.client}
                      error={propsForm.errors.client}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('client', value);
                      }}
                      options={clientList}
                      getOptionLabel={option => option?.fullName}
                      renderInput={params => (
                        <TextField
                          error={propsForm.errors.client}
                          value={propsForm.values.client}
                          fullWidth
                          {...params}
                          variant="outlined"
                          label="Colaborador"
                          placeholder="Colaborador"
                        />
                      )}
                    />
                    <ErrorMessage name="client">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="type">Tipo</InputLabel>
                    <Select
                      fullWidth
                      labelId="type"
                      name="type"
                      variant="outlined"
                      value={propsForm.values.type}
                      error={propsForm.errors.type}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('type', value.key);
                      }}
                    >
                      {typeList.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="type">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="classification">Clasificación</InputLabel>
                    <Select
                      fullWidth
                      labelId="classification"
                      name="classification"
                      variant="outlined"
                      value={propsForm.values.classification}
                      error={propsForm.errors.classification}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('classification', value.key);
                      }}
                    >
                      {classificationList.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="classification">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <Grid item xs={4}>
                    <Checkbox
                      name="isGeneric"
                      value={propsForm.values.isGeneric}
                      onChange={e =>
                        propsForm.setFieldValue('isGeneric', e.target.value)
                      }
                      disabled={propsForm.values.client}
                      checked={propsForm.values.isGeneric}
                      error={propsForm.errors.isGeneric}
                    />
                    <FormLabel component="label" color="primary">
                      Es genérico?
                    </FormLabel>
                    <ErrorMessage name="isGeneric">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <SectionDivider />
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={history.goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={loading && limitGlobal / 1000000 > 100}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProcessFormFiles;
