import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import MUIDataTable, { TableToolbar, TableBody } from 'mui-datatables';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TodayIcon from '@material-ui/icons/Today';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// GraphQl
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import CustomCard from '../../CustomCard';
import moment from 'moment';
import {
  generateColumn,
  edgeToList,
  errorMessage,
  stringToDate,
  getOptions,
  downloadBase64PlainText,
} from '../../../utils/commonFunctions';
import { textLabels } from '../../../translations/components/MUIDataTable';
import LoadingIndicator from '../../LoadingIndicator';

import { DatePicker } from '@material-ui/pickers';
import { Field } from 'formik';
import { LIST_CLIENT_USERS } from '../Users/queries';
import { LIST_FUNDS } from '../Fund/queries';
import {
  LIST_SECONDARY_MARKETS,
  DELETE_SECONDARY_MARKET,
  SEND_SECONDARY_MARKET_CONTRACT,
  GET_SECONDARY_MARKET_REPORT,
} from './queries';
import CustomOptions from 'src/components/CustomOptions';
import Money from 'src/components/Money';
import Date from 'src/components/Date';
import NumberFormat from 'react-number-format';

// Styles & Images
import useClasses from './styles';

export const SECONDARY_MARKET_STATES = {
  P: 'Pendiente',
  V: 'Verificado',
  S: 'Firmado',
};

function ToolB(props) {
  const {
    toolBarProps,
    setClient,
    setFund,
    setDateStart,
    setDateEnd,
    filters,
  } = props;

  const [funds, setFunds] = useState([]);
  const [clients, setClients] = useState([]);

  const classes = useClasses();
  const dateFormat = 'YYYY-MM-DD';

  const {
    loading: loadingFunds,
    error: errorFunds,
    data: dataFunds,
  } = useQuery(LIST_FUNDS);
  const {
    loading: loadingClients,
    error: errorClients,
    data: dataClients,
  } = useQuery(LIST_CLIENT_USERS);

  useEffect(() => {
    if (dataClients && dataFunds) {
      const parseFunds = edgeToList(dataFunds, 'listFunds').map(item => {
        return {
          id: item.id,
          name: item.businessName,
        };
      });
      const parseClients = edgeToList(dataClients, 'listClients')
        .filter(element => !element.user.isStaff && element.user.isActive)
        .map(item => {
          return {
            id: item.id,
            name: `${item.user.firstName} ${item.user.lastName}`,
          };
        });
      setFunds(parseFunds);
      setClients(parseClients);
    }
  }, [dataClients, dataFunds]);

  function handleChangeFund(event, value) {
    event.preventDefault();
    setFund(value);
  }
  function handleChangeClient(event, value) {
    event.preventDefault();
    setClient(value);
  }

  return loadingClients || loadingFunds ? (
    <LoadingIndicator />
  ) : (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableToolbar {...toolBarProps} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.autocomplete}>
              <Autocomplete
                loading={loadingFunds}
                loadingText="Cargando ..."
                clearText="Borrar filtro"
                closeText="Cerrar"
                openText="Abrir"
                noOptionsText="Sin coincidencias"
                value={filters.fund}
                options={funds}
                getOptionLabel={option => option.name}
                onChange={handleChangeFund}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Fondos"
                    placeholder="Fondos"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} className={classes.autocomplete}>
              <Autocomplete
                loading={loadingClients}
                loadingText="Cargando ..."
                clearText="Borrar filtro"
                closeText="Cerrar"
                openText="Abrir"
                noOptionsText="Sin coincidencias"
                value={filters.client}
                options={clients}
                getOptionLabel={option => option.name}
                onChange={handleChangeClient}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Clientes"
                    placeholder="Clientes"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} className={classes.datePickers}>
              <DatePicker
                autoOk
                fullWidth
                format="YYYY-MM-DD"
                value={filters.dateStart}
                maxDate={filters.dateEnd || Date('2100-01-01')}
                label="Fecha inicio"
                emptyLabel="Ingrese una fecha de inicio"
                inputVariant="outlined"
                color="secondary"
                onChange={date => {
                  setDateStart(date.format(dateFormat));
                }}
                error=""
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} className={classes.datePickers}>
              <DatePicker
                fullWidth
                autoOk
                format="YYYY-MM-DD"
                value={filters.dateEnd}
                minDate={filters.dateStart || Date('1900-01-01')}
                emptyLabel="Ingrese una fecha final"
                label="Fecha fin"
                inputVariant="outlined"
                color="secondary"
                onChange={date => {
                  setDateEnd(date.format(dateFormat));
                }}
                error=""
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

ToolB.propTypes = {
  toolBarProps: PropTypes.shape().isRequired,
  setClient: PropTypes.func.isRequired,
  setFund: PropTypes.func.isRequired,
  setDateStart: PropTypes.func.isRequired,
  setDateEnd: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

function SecondaryMarketList() {
  const [contractsList, setContractsList] = useState([]);

  const [fund, setFund] = useState(null);
  const [client, setClient] = useState(null);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useClasses();

  const [getSecondaryMarkets, { loading }] = useLazyQuery(
    LIST_SECONDARY_MARKETS,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data && data.listSecondaryMarkets) {
          let marketList = edgeToList(data, 'listSecondaryMarkets');
          marketList = marketList.map(item => {
            const parseItem = { ...item };
            parseItem.fund = item.fund.businessName;
            parseItem.buyer = edgeToList(item, 'buyer');
            parseItem.seller = edgeToList(item, 'seller');
            parseItem.amount = {
              amount: item.fee * item.feeSaleValue,
              symbol: item.fund.currency.symbol,
              code: item.fund.currency.code,
            };
            parseItem.options = { id: item.id, state: item.state };
            return parseItem;
          });
          setContractsList(marketList);
        }
      },
      onError() {
        errorMessage(
          'Error al obtener la lista de tipos de contrato',
          enqueueSnackbar
        );
      },
    }
  );

  const [deleteSecondaryMarket] = useMutation(
    DELETE_SECONDARY_MARKET,
    getOptions({
      mutationName: 'deleteSecondaryMarket',
      modelName: 'secondaryMarket',
      message: 'Registro eliminado con éxito.',
      enqueueSnackbar,
      completeCallback: () => {
        const variables = {};
        if (fund) variables.fund = fund.id;
        if (client) variables.clientId = client.id;
        if (dateStart) variables.initialDate = stringToDate(dateStart);
        if (dateEnd) variables.finalDate = stringToDate(dateEnd);
        getSecondaryMarkets({ variables });
      },
    })
  );

  const [sendContract, { loading: loadingSendContract }] = useMutation(
    SEND_SECONDARY_MARKET_CONTRACT,
    getOptions({
      mutationName: 'sendSecondaryMarketContractMutation',
      modelName: 'secondaryMarket',
      message: 'Contratos enviados correctamente.',
      enqueueSnackbar,
      completeCallback: () => {
        const variables = {};
        if (fund) variables.fund = fund.id;
        if (client) variables.clientId = client.id;
        if (dateStart) variables.initialDate = stringToDate(dateStart);
        if (dateEnd) variables.finalDate = stringToDate(dateEnd);
        getSecondaryMarkets({ variables });
      },
    })
  );

  const [getReport, { loading: loadingReport }] = useLazyQuery(
    GET_SECONDARY_MARKET_REPORT,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (
          data.secondaryMarketReport.csv &&
          data.secondaryMarketReport.filename
        ) {
          downloadBase64PlainText(
            data.secondaryMarketReport.csv,
            data.secondaryMarketReport.filename
          );
        }
      },
      onError(error) {
        errorMessage(
          'Ocurrió un error al solicitar el archivo, inténtelo más tarde',
          enqueueSnackbar
        );
      },
    }
  );

  useEffect(() => {
    getSecondaryMarkets();
  }, []);
  useEffect(() => {
    const variables = {};
    if (fund) variables.fund = fund.id;
    if (client) variables.clientId = client.id;
    if (dateStart) variables.initialDate = stringToDate(dateStart);
    if (dateEnd) variables.finalDate = stringToDate(dateEnd);
    getSecondaryMarkets({ variables });
  }, [fund, client, dateStart, dateEnd]);

  const columnOptions = {
    filter: false,
    sort: true,
  };

  const listColumns = [
    generateColumn('Fondo', 'fund', columnOptions),
    generateColumn('Compradores', 'buyer', {
      ...columnOptions,
      customBodyRender: buyers => (
        <div className={classes.multilineCell}>
          {buyers.map(({ client: buyer }) => (
            <span>{`${buyer.user.firstName} ${buyer.user.lastName} ${buyer.user.maternalSurname}`}</span>
          ))}
        </div>
      ),
    }),
    generateColumn('Vendedores', 'seller', {
      ...columnOptions,
      customBodyRender: sellers => (
        <div className={classes.multilineCell}>
          {sellers.map(({ user: seller }) => (
            <span>{`${seller.firstName} ${seller.lastName} ${seller.maternalSurname}`}</span>
          ))}
        </div>
      ),
    }),
    generateColumn('Cuotas', 'fee', {
      ...columnOptions,
      customBodyRender: fees => (
        <NumberFormat displayType="text" thousandSeparator value={fees} />
      ),
    }),
    generateColumn('Monto', 'amount', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        return (
          <Money
            currency={`${value.code}${value.symbol}`}
            amount={value.amount}
          />
        );
      },
    }),
    generateColumn('Fecha de registro', 'raisingNumber', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        return <Date date={value} />;
      },
    }),
    generateColumn('Porcentaje', 'buyer', {
      ...columnOptions,
      customBodyRender: buyers => (
        <div className={classes.multilineCell}>
          {buyers.map(({ participationPercentage }) => (
            <span>{`${participationPercentage} %`}</span>
          ))}
        </div>
      ),
    }),
    generateColumn('Estado', 'state', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        return SECONDARY_MARKET_STATES[value];
      },
    }),
    generateColumn('Opciones', 'options', {
      sort: false,
      filter: false,
      viewColumns: false,
      customBodyRender: value => {
        const { id, state } = value;
        return (
          <>
            {state === 'P' ? (
              <CustomOptions
                id={id}
                model="Mercado Secundario"
                options={['odelete', 'ocustom']}
                handleDelete={() => {
                  deleteSecondaryMarket({ variables: { id } });
                }}
                customOption={
                  <>
                    <Tooltip arrow title="Enviar contratos" placement="top">
                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.optionButtons}
                        disabled={loadingSendContract}
                        onClick={() => sendContract({ variables: { id } })}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            ) : (
              <></>
            )}
          </>
        );
      },
    }),
    generateColumn('ID', 'id', {
      sort: true,
      filter: false,
      display: false,
      customBodyRender: value => <>{value}</>,
    }),
  ];

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `${history.location.pathname}/${
          rowData[rowData.length - 1].props.children
        }`
      );
    }
  };

  const listOptions = {
    responsive: 'standard',
    selectableRows: 'none',
    filter: false,
    print: false,
    download: false,
    textLabels,
    onRowClick: rowData => handleRowClick(rowData),
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Descargar reporte">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={loadingReport}
              onClick={() => {
                const variables = {};
                if (fund) variables.fund = fund.id;
                if (client) variables.clientId = client.id;
                if (dateStart) variables.initialDate = stringToDate(dateStart);
                if (dateEnd) variables.finalDate = stringToDate(dateEnd);
                getReport({ variables });
              }}
            >
              Descargar reporte
            </Button>
          </Tooltip>
          <Tooltip title="Nuevo mercado secundario">
            <IconButton
              color="secondary"
              onClick={() => history.push(`${history.location.pathname}/crear`)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };

  return (
    <CustomCard
      disableFlex
      showHeader={false}
      content={
        false ? (
          <LoadingIndicator />
        ) : (
          <MUIDataTable
            options={listOptions}
            title="Mercado secundario"
            columns={listColumns}
            data={contractsList}
            components={{
              TableToolbar: props => (
                <ToolB
                  toolBarProps={props}
                  setFund={setFund}
                  setClient={setClient}
                  setDateStart={setDateStart}
                  setDateEnd={setDateEnd}
                  filters={{ fund, client, dateStart, dateEnd }}
                />
              ),
              TableBody: props =>
                loading ? <LoadingIndicator /> : <TableBody {...props} />,
            }}
          />
        )
      }
    />
  );
}

export default SecondaryMarketList;
