import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

// Material Resources
import { Grid, FormHelperText } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

// GraphQl
import { gql } from 'apollo-boost';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

// Components
import CustomSelect from '../CustomSelect';
import LoadingIndicator from '../../LoadingIndicator';
import AutocompleteInfiniteLoader from '../AutocompleteInfiniteLoader';

import { edgeToList } from '../../../utils/commonFunctions';

export const LIST_COUNTRIES = gql`
  {
    listCountries {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;
export const LIST_COUNTIES = gql`
  query listCounties(
    $country: ID!
    $first: Int
    $after: String
    $nameStart: String
  ) {
    listCounties(
      country: $country
      first: $first
      after: $after
      name_Istartswith: $nameStart
    ) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
export const LIST_CITIES = gql`
  query listCities($county: ID!) {
    listCities(county: $county) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const LIST_DISTRICTS = gql`
  query listDistricts($city: ID) {
    listDistricts(city: $city) {
      edges {
        node {
          id
          name
          ubigeo
        }
      }
    }
  }
`;

export const reorderCountry = countryList => {
  const newList = [...countryList];
  const index = newList.findIndex(value => value.code === 'PE');

  if (index !== -1) {
    newList.unshift(newList.splice(index, 1)[0]);
  }
  return newList;
};

export const reorderCounty = countyList => {
  const newList = [...countyList];

  const indexLima = newList.findIndex(value => value.name === 'Lima');
  const indexArequipa = newList.findIndex(value => value.name === 'Arequipa');

  if (indexLima !== -1 && indexArequipa !== -1) {
    newList.unshift(newList.splice(indexArequipa, 1)[0]);
    newList.unshift(newList.splice(indexLima, 1)[0]);
  }
  return newList;
};

function PlacesSelects(props) {
  const {
    country,
    county,
    city,
    district,
    gridMD,
    compact,
    ubigeo,
    indicators,
    errors,
    isStepForm,
  } = props;

  const [countryID, setCountryID] = useState(country);
  const [countyID, setCountyID] = useState(county);
  const [cityID, setCityID] = useState(city);
  const [districtID, setDistrictID] = useState(district);
  const [hasChanged, setHasChanged] = useState(false);

  const [lastCounty, setLastCounty] = useState('');
  const [counties, setCounties] = useState([]);
  const [countyNameStart, setCountyNameStart] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);
  const [firstRender, setFirstRender] = useState(true);

  const { setFieldValue, values: formValues } = useFormikContext();

  useEffect(() => {
    setCountryID(country);
    if (typeof county === 'object' && 'id' in county) {
      setCountyID(county.id);
    } else {
      setCountyID(county);
    }
    setCityID(city);
    setDistrictID(district);
  }, [country, county, city, district]);

  const {
    loading: countryLoading,
    data: countryData,
    error: countryError,
  } = useQuery(LIST_COUNTRIES);

  const isPeru = id => {
    if (
      countryData &&
      countryData.listCountries &&
      countryData.listCountries.edges
    ) {
      return !!countryData.listCountries.edges.find(val => {
        return val.node && val.node.id === id && val.node.code === 'PE';
      });
    }
    return false;
  };

  const [
    getDistricts,
    { error: districtError, data: districtData, loading: districtLoading },
  ] = useLazyQuery(LIST_DISTRICTS, {
    onCompleted(data) {
      if (
        data.listDistricts &&
        data.listDistricts.edges &&
        data.listDistricts.edges.length > 0 &&
        indicators.district
      ) {
        if (!hasChanged && district !== '') {
          const firstDistrict = data.listDistricts.edges.find(
            ctry => ctry.node.id === countryID,
          );
          if (firstDistrict) {
            if (ubigeo !== 0) {
              setFieldValue(indicators.ubigeo.name, firstDistrict.node.ubigeo);
            }
            setFieldValue(indicators.district.name, firstDistrict.node.id);
          }
        } else {
          const firstDistrict = data.listDistricts.edges[0].node;
          if (ubigeo !== 0) {
            setFieldValue(indicators.ubigeo.name, firstDistrict.ubigeo);
          }
          setFieldValue(indicators.district.name, firstDistrict.id);
        }
      }
    },
  });

  const [
    getCities,
    { error: cityError, data: cityData, loading: cityLoading },
  ] = useLazyQuery(LIST_CITIES, {
    onCompleted(data) {
      if (
        data.listCities &&
        data.listCities.edges &&
        data.listCities.edges.length > 0
      ) {
        if (!hasChanged && cityID !== '') {
          const firstCity = data.listCities.edges.find(
            ctry => ctry.node.id === cityID,
          );
          if (firstCity) {
            setCityID(firstCity.node.id);
            setFieldValue(indicators.city.name, firstCity.node.id);
            getDistricts({ variables: { city: cityID } });
          }
        } else {
          const firstCity = data.listCities.edges[0].node.id;
          setCityID(firstCity);
          setFieldValue(indicators.city.name, firstCity);
          getDistricts({ variables: { city: firstCity } });
        }
      }
    },
  });

  const [
    getCounties,
    { error: countyError, data: countyData, loading: countyLoading },
  ] = useLazyQuery(LIST_COUNTIES, {
    onCompleted(data) {
      if (
        data.listCounties &&
        data.listCounties.edges &&
        data.listCounties.edges.length > 0
      ) {
        const newCounties = edgeToList(data, 'listCounties');
        if (newCounties.length > 0) {
          setLastCounty(data.listCounties.pageInfo.endCursor);
          setCounties([...counties, ...edgeToList(countyData, 'listCounties')]);
          setHasNextPage(data.listCounties.pageInfo.hasNextPage);
        }
      }
    },
    onError: error => {
      const { graphQLErrors } = error;
      console.error('Counties error: ', graphQLErrors);
      setFieldValue(indicators.country.name, '');
    },
  });

  if (countryError) {
    console.error('country error', countryError);
  }
  if (countyError) {
    console.error('county error', countyError);
  }
  if (cityError) {
    console.error('city error', cityError);
  }
  if (districtError) {
    console.error('district error', districtError);
  }

  useEffect(() => {
    if (!countryLoading && countryID) {
      if (countryID !== '') {
        getCounties({ variables: { country: countryID, first: 100 } });
        setFieldValue(indicators.country.name, countryID);
      } else {
        setFieldValue(indicators.country.name, '');
      }
    }
  }, [countryLoading, countryID]);

  useEffect(() => {
    if (
      !countyLoading &&
      countyData &&
      countyData.listCounties &&
      countyData.listCounties.edges &&
      countyData.listCounties.edges.length > 0
    ) {
      if (!hasChanged && countyID !== '') {
        const firstCounty = countyData.listCounties.edges.find(
          ctry => ctry.node.id === countyID,
        );
        if (firstCounty) {
          setFieldValue(indicators.county.name, firstCounty.node);
          if (!compact && isPeru(countryID)) {
            getCities({ variables: { county: countyID } });
          }
        }
      } else {
        const firstCounty = reorderCounty(
          edgeToList(countyData, 'listCounties'),
        )[0];
        setCountyID(firstCounty.id);
        if (firstCounty) {
          setFieldValue(indicators.county.name, firstCounty);
        }
        if (!compact && isPeru(countryID)) {
          getCities({ variables: { county: firstCounty.id } });
        }
      }
    }
  }, [countyData, countyLoading]);

  function handleCountryChange(event) {
    setHasChanged(true);
    setCountryID(event.target.value);
    setCounties([]);

    setFirstRender(true);
    if (!compact && !isPeru(event.target.value)) {
      setFieldValue(indicators.city.name, '');
      setFieldValue(indicators.district.name, '');
    }
  }
  function onCountyInputChange(event, value) {
    if (event && value && !isPeru(countryID)) {
      setCounties([]);
      setFirstRender(true);
      setHasChanged(false);
      setCountyNameStart(value);
      getCounties({
        variables: {
          country: countryID,
          first: 100,
          nameStart: value,
        },
      });
    }
  }

  function handleCountyChange(event, value) {
    event.preventDefault();

    setCountyID(value.id);
    setFirstRender(true);
    setFieldValue(indicators.county.name, value);
    setHasChanged(true);
    setCountyNameStart('');

    if (!compact && isPeru(countryID)) {
      getCities({ variables: { county: value.id } });
    }
  }

  function handleCityChange(event) {
    setCityID(event.target.value);
    setHasChanged(true);
    getDistricts({ variables: { city: event.target.value } });
  }

  function handleDistrictChange(event) {
    setDistrictID(event.target.value);
    const dist = edgeToList(districtData, 'listDistricts').find(
      d => d.id === event.target.value,
    );
    if (dist && ubigeo) {
      setFieldValue(indicators.ubigeo.name, dist.ubigeo);
    }
  }

  const loadMoreItems = (startIndex, stopIndex) => {
    setFirstRender(false);
    const variables = { country: countryID, first: 100, after: lastCounty };
    if (countyNameStart !== '') {
      variables.nameStart = countyNameStart;
    }
    getCounties({
      variables,
    });
  };

  return (
    <>
      <Grid item md={gridMD[0]} xs={12}>
        {!countryLoading ? (
          <CustomSelect
            id={`${indicators.country.name}-select`}
            name={indicators.country.name}
            label={indicators.country.label}
            data={reorderCountry(edgeToList(countryData, 'listCountries'))}
            mapData={{ value: 'id', label: 'name' }}
            onChange={handleCountryChange}
            error={errors.country || ''}
            isStepForm={isStepForm}
          />
        ) : (
          <LoadingIndicator size="xs" />
        )}
      </Grid>

      <Grid item md={gridMD[1]} xs={12}>
        <AutocompleteInfiniteLoader
          label={indicators.county.label}
          name={indicators.county.name}
          onChange={handleCountyChange}
          onInputChange={onCountyInputChange}
          loadMoreItems={loadMoreItems}
          isLoading={countyLoading}
          options={reorderCounty(counties)}
          hasNextPage={hasNextPage}
          firstRender={firstRender}
          countyNameStart={countyNameStart}
          error={errors.county}
        />
      </Grid>
      {!compact && isPeru(countryID) && (
        <>
          <Grid item md={gridMD[2]} xs={12}>
            {!cityLoading ? (
              <CustomSelect
                id={`${indicators.city.name}-select`}
                name={indicators.city.name}
                label={indicators.city.label}
                data={edgeToList(cityData, 'listCities')}
                mapData={{ value: 'id', label: 'name' }}
                onChange={handleCityChange}
                error={errors.city || ''}
                isStepForm={isStepForm}
              />
            ) : (
              <LoadingIndicator size="xs" />
            )}
          </Grid>
          <Grid item md={gridMD[3]} xs={12}>
            {!districtLoading ? (
              <CustomSelect
                id={`${indicators.district.name}-select`}
                name={indicators.district.name}
                label={indicators.district.label}
                data={edgeToList(districtData, 'listDistricts')}
                mapData={{ value: 'id', label: 'name' }}
                onChange={handleDistrictChange}
                error={errors.district || ''}
                isStepForm={isStepForm}
              />
            ) : (
              <LoadingIndicator size="xs" />
            )}
          </Grid>
          {ubigeo !== 0 && (
            <Grid item md={ubigeo} xs={12}>
              {!districtLoading ? (
                <TextField
                  name={indicators.ubigeo.name}
                  label={indicators.ubigeo.label}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <LoadingIndicator size="xs" />
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
}
PlacesSelects.propTypes = {
  country: PropTypes.string,
  county: PropTypes.string,
  city: PropTypes.string,
  district: PropTypes.string,
  compact: PropTypes.bool,
  ubigeo: PropTypes.number,
  gridMD: PropTypes.arrayOf(PropTypes.number),
  errors: PropTypes.shape(),
  indicators: PropTypes.shape(),
  isStepForm: PropTypes.bool,
};
PlacesSelects.defaultProps = {
  compact: false,
  ubigeo: 0,
  errors: {
    country: '',
    county: '',
    city: '',
    district: '',
  },
  country: '',
  county: '',
  city: '',
  district: '',
  gridMD: [3, 3, 3, 3],
  indicators: {
    country: { name: 'country', label: 'País' },
    county: { name: 'county', label: 'Region' },
    city: { name: 'city', label: 'Provincia' },
    district: { name: 'district', label: 'Distrito' },
    ubigeo: { name: 'ubigeo', label: 'Ubigeo' },
  },
  isStepForm: false,
};
export default PlacesSelects;
