import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  dashboardPage: {},
  logo: {
    width: 180,
  },
  layout: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    overflow: 'scroll',
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingTop: '10px',
    gap: '10px',
    height: '70%',
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  },

  card: {
    background: '#fff',
    borderLeft: 'solid 4px #F97A00',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '2px',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    boxShadow: '0px 4px 10px 0px rgba(166, 193, 211, 0.60)',
    maxWidth: '100%',
    padding: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    '&:hover': {
      boxShadow: '0px 8px 20px 0px rgba(166, 193, 211, 0.65)',
    },
  },
  cardDark: {
    background: '#2F2F2F',
    borderLeft: 'solid 4px #A6C1D3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '2px',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    maxWidth: '100%',
    padding: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    '&:hover': {
      boxShadow: '0px 8px 20px 0px rgba(166, 193, 211, 0.15)',
    },
  },
  title: {
    fontSize: '1rem',
    color: '#292D32',
    fontWeight: 700,
  },

  nameCard: {
    fontSize: theme.typography.body1,
    color: '#606060',
    fontWeight: 500,
  },
  nameCardDark: {
    fontSize: theme.typography.body1,
    color: '#B8B8B8',
    fontWeight: 500,
  },
  titleDark: {
    fontSize: '1rem',
    color: '#ECECEC',
    fontWeight: 700,
  },
  sectionDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  cardsDiv: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    // gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
}));
