/*
 * HomePage
 */

import React, { useEffect, useState } from 'react';
// Material Resources
import { Menu, MenuItem } from '@material-ui/core';
// Components & Others
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { delAuthUser } from 'src/utils/storage';
import { removeClient } from 'src/store/sharedStore/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Styles & Images
import useStyles from './styles';
import ArrowDwnIcon from './svg/arrowDwn';
import EmployeeNotifications from './notifications';
import NotificationsIcon from './svg/notificationsIcon';
import getClientMode from '../../../../../store/utils-store/selectors';
import Avatar from './avatar';
import PopUpNotificationsDashboard from './notifications/pop-up-notification';

function EmployeeProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const modeClient = useSelector(getClientMode());
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseUser = () => {
    setAnchorEl(null);
  };
  const handleProfileUser = () => {
    history.push('/cliente/perfil');
    handleCloseUser();
  };
  const handleLogOut = () => {
    delAuthUser();
    dispatch(removeClient());
    localStorage.clear();
    history.push('/');
  };
  const handleClickUser = event => {
    setAnchorEl(event.currentTarget);
  };
  const classes = useStyles();
  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [clients]);

  const welcomeStyle = modeClient ? classes.welcomeDark : classes.welcome;
  const nameStyle = modeClient ? classes.nameDark : classes.name;

  return (
    <>
      <div className={classes.layout} style={{ position: 'relative' }}>
        <div className={classes.mainDiv}>
          <div className={classes.profileDiv}>
            <Avatar />
            <div className={classes.welcomeDiv}>
              <div style={{ display: 'flex', gap: '4px' }}>
                <p className={welcomeStyle}>Hola,</p>
                <p className={nameStyle}>
                  {clients && `${clients.currentUser.firstName}`}
                </p>
              </div>
              <div role="button" tabIndex={0} onClick={handleClickUser}>
                <ArrowDwnIcon bg={modeClient ? '#A6C1D3' : '#000'} />
              </div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseUser}
              >
                <MenuItem
                  onClick={handleProfileUser}
                  id="cambio_contrasena_menu"
                >
                  Cambio de contraseña
                </MenuItem>
                <MenuItem onClick={handleLogOut} id="salir_menu">
                  Salir
                </MenuItem>
              </Menu>

              {windowWidth < 980 && (
                <div onClick={() => setOpen(!open)}>
                  <NotificationsIcon bg={modeClient ? '#A6C1D3' : '#000'} />
                  {open && <PopUpNotificationsDashboard />}
                </div>
              )}
            </div>
          </div>
          {windowWidth > 900 && <EmployeeNotifications />}
        </div>
      </div>
    </>
  );
}

export default EmployeeProfile;
