import React from 'react';
import useStyles from './styles';
import HelpIcon from '../svg/helpIcon';
import ProgressCircle from './progress-circle';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';

import { useHistory } from 'react-router-dom';
const textCompletePercentageBanner = [
  {
    id: 3,
    title: ' ¡Gracias por llenar tu Ficha Colaborador!',
    content: '',
    action: '/cliente/ficha-cliente',
  },
];

const textPercentageBanner = [
  {
    id: 2,
    title: ' ¡Continúa para finalizar tu Ficha Colaborador!',
    content: '',
    action: '/cliente/ficha-cliente',
  },
];

const textWelcomeBanner = [
  {
    id: 1,
    title: '¡Bienvenido a Core Capital! ',
    content:
      'Queremos asegurarnos de que tengas la mejor experiencia posible. Por favor, dedica unos minutos a completar tu Ficha Colaborador',
    action: '/cliente/ficha-cliente',
  },
];

function Banners() {
  const classes = useStyles();
  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });
  const history = useHistory();
  const percentage =
    clients?.currentUser?.clientSet.edges[0].node.completitionPercentage;
  return (
    <>
      <div className={classes.mainDiv}>
        {percentage > 0 && percentage < 100 && (
          <>
            {textPercentageBanner.map(item => {
              return (
                <>
                  <div className={classes.divProgress}>
                    <div className={classes.devTextProgress}>
                      <p className={classes.title}>{item.title}</p>
                      {/* <p className={classes.content}>{item.content}</p> */}
                      <div className={classes.btnDiv}>
                        <div
                          role="button"
                          onClick={() => history.push(item.action)}
                          tabIndex={0}
                          className={classes.btn}
                          onKeyDown={e => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              history.push(item.action);
                            }
                          }}
                        >
                          Continuar
                        </div>
                      </div>
                    </div>

                    <ProgressCircle percentage={percentage} />
                  </div>
                </>
              );
            })}
          </>
        )}
        {percentage == 100 && (
          <>
            {textCompletePercentageBanner.map(item => {
              return (
                <>
                  <div className={classes.divProgress}>
                    <div className={classes.devTextProgress}>
                      <p className={classes.title}>{item.title}</p>
                      {/* <p className={classes.content}>{item.content}</p> */}
                    </div>
                    <ProgressCircle percentage={percentage} />
                  </div>
                </>
              );
            })}
          </>
        )}
        {percentage == 0 && (
          <>
            {textWelcomeBanner.map(item => {
              return (
                <>
                  <p className={classes.title}>{item.title}</p>
                  <p className={classes.content}>{item.content}</p>
                  <div className={classes.btnDiv}>
                    <div
                      role="button"
                      onClick={() => history.push(item.action)}
                      tabIndex={0}
                      className={classes.btn}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          history.push(item.action);
                        }
                      }}
                      className={classes.btn}
                    >
                      Completar Ficha
                    </div>
              
                    {/* {percentage > 0 && <ProgressCircle percentage={percentage} />} */}
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}

export default Banners;
