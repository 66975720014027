import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, FormHelperText } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormikContext } from 'formik';

import ListboxComponent from './listBoxComponent';

// Styles & Images
import useStyles from './styles';

const TOTAL_REFETCH_ITEMS = 30;

const AutocompleteInfiniteLoader = props => {
  const {
    label,
    name,
    onChange,
    onInputChange,
    options,
    endCursor,
    isLoading,
    hasNextPage,
    loadMoreItems,
    firstRender,
    countyNameStart,
    error,
    ...other
  } = props;

  const classes = useStyles();

  const { values: formValues } = useFormikContext();

  const isItemLoaded = index => {
    if (!hasNextPage) {
      return true;
    }
    return !!options[index];
  };

  const getItemCount = () => {
    if (!hasNextPage) {
      return options.length;
    }

    return options.length + 1;
  };

  const itemCount = getItemCount();

  const ListboxProps = {
    isItemLoaded,
    loadMoreItems,
    itemCount,
    isLoadingMore: isLoading,
  };

  return (
    <>
      <Autocomplete
        id={`auto-${name}`}
        disableListWrap
        classes={classes}
        ListboxComponent={props => (
          <ListboxComponent
            hasNextPage={hasNextPage}
            firstRender={firstRender}
            {...props}
          />
        )}
        ListboxProps={ListboxProps}
        options={options}
        getOptionLabel={option => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={option => <Typography noWrap>{option.name}</Typography>}
        value={formValues[name]}
        openOnFocus
        blurOnSelect
        fullWidth
        disableClearable
        loadingText="Cargando..."
        noOptionsText="Escriba para buscar elementos"
        onChange={onChange}
        onInputChange={onInputChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            name={name}
            label={label}
            error={error && error !== ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder={label}
            fullWidth
          />
        )}
        {...other}
      />
      {error !== '' && (
        <FormHelperText error style={{ marginLeft: 14, marginRight: 14 }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

AutocompleteInfiniteLoader.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  endCursor: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  loadMoreItems: PropTypes.func.isRequired,
  firstRender: PropTypes.bool.isRequired,
  countyNameStart: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

export default AutocompleteInfiniteLoader;
