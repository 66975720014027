import React, { useEffect } from 'react';

import PropTypes, { array, bool } from 'prop-types';
import { Grid, FormLabel, TextField } from '@material-ui/core';
import { Field, useFormikContext, useField } from 'formik';
import { Checkbox } from 'formik-material-ui';
import {
  sortableContainer,
  sortableElement,
  arrayMove,
} from 'react-sortable-hoc';

import useStyles from './styles';
import Checkboxes from '../../FormComponents/Checkboxes';
import InputNumber from '../../FormComponents/InputNumber';
import InputOnlyText from 'src/components/FormComponents/InputOnlyText';
import ClientTexts from '../../FormComponents/ClientTexts';
import CustomSelect from '../../FormComponents/CustomSelect';
import CustomMultiSelectSearch from '../../FormComponents/CustomMultiSelectSearch';
import RadioButtons from '../../FormComponents/RadioButtons';
import PlacesSelects from '../../FormComponents/PlacesSelects';
import CountrySelect from '../../FormComponents/CountrySelect';
import SelectOtherInput from '../../FormComponents/SelectOtherInput';
import ConditionalSelectsInput from '../../FormComponents/ConditionalSelectsInput';
import NumberFormatInput from '../../FormComponents/NumberFormatInput';
import NationalitySelect from '../../FormComponents/NationalitySelect';
import ServiceTimeInputs from '../../FormComponents/ServiceTiemeInputs';
import InputBox from '../../FormComponents/InputBox';
import DatePickerField from '../../FormComponents/DatePicker';
import ImageInput from '../../FormComponents/ImageInput';
import FileInput from '../../FormComponents/FileInput';
import DateRangePicker from '../../FormComponents/DateRangePicker';
import PeruCountySelect from 'src/components/FormComponents/PeruCountySelect';

export const INPUT_VARIANT = {
  node: 'CustomNode',
  inputBox: 'InputBox',
  text: 'TextField',
  onlyText: 'InputOnlyText',
  number: 'InputNumber',
  date: 'InputDate',
  dateRange: 'InputDateRange',
  image: 'InputImage',
  file: 'InputFile',
  formatNumber: 'InputFormatNumber',
  select: 'CustomSelect',
  places: 'PlacesSelects',
  multiSelectSearch: 'CustomMultiSelectSearch',
  country: 'CountrySelect',
  peruCounties: 'PeruCountySelect',
  nationality: 'NationalitySelect',
  radio: 'RadioButtons',
  checkbox: 'Checkboxes',
  singleCheckbox: 'SingleCheckbox',
  paragraph: 'ParagraphText',
  otherSelect: 'SelectOtherInput',
  conditionalSelects: 'ConditionalSelectsInput',
  serviceTime: 'ServiceTimeInputs',
  sortable: 'Sortable',
};

// Custom component for Formik to catch the values
const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => <TextField {...field} {...props} />;

const SortableItem = sortableElement(({ value }) => (
  <li>
    <FormLabel component="label" color="primary">
      {value}
    </FormLabel>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ol>{children}</ol>;
});

function mapOrder(array, order, key) {
  if (array && order) {
    array.sort(function(a, b) {
      var A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
  }
  return array;
}

const Fields = (type, opts) => {
  const {
    field,
    errors,
    initialValues,
    setFieldValue,
    model,
    isStepForm,
    formValues,
  } = opts;

  const cases = {
    [INPUT_VARIANT.text]: (
      <Field
        name={field.name}
        label={field.label}
        disabled={field.disabled}
        autoFocus={field.autofocus}
        error={errors[field.name] ? errors[field.name] !== '' : false}
        helperText={errors[field.name]}
        variant="outlined"
        inputProps={{
          readOnly: field.readOnly,
        }}
        fullWidth
        component={CustomInputComponent}
      />
    ),
    [INPUT_VARIANT.onlyText]: (
      <InputOnlyText
        name={field.name}
        label={field.label}
        disabled={field.disabled}
        variant="outlined"
        fullWidth
        inputProps={{
          readOnly: field.readOnly,
        }}
      />
    ),
    [INPUT_VARIANT.number]: (
      <InputNumber
        name={field.name}
        label={field.label}
        disabled={field.disabled}
        variant="outlined"
        fullWidth
        inputProps={{
          readOnly: field.readOnly,
        }}
      />
    ),
    [INPUT_VARIANT.date]: (
      <Field
        name={field.name}
        label={field.label}
        disabled={field.disabled}
        views={field.views}
        variant="outlined"
        openTo={field.dateT ? field.dateT : 'date'}
        component={DatePickerField}
        customFormat={field.customFormat}
        fullWidth
      />
    ),
    [INPUT_VARIANT.dateRange]: <DateRangePicker fieldData={field.fieldData} />,
    [INPUT_VARIANT.image]: (
      <Field
        name={field.name}
        label={field.label}
        oldImage={model ? model[field.name] : initialValues[field.name]}
        variant="outlined"
        component={ImageInput}
        fullWidth
      />
    ),
    [INPUT_VARIANT.file]: (
      <Field
        name={field.name}
        label={field.label}
        accept={field.accept}
        oldFile={model ? model[field.name] : initialValues[field.name]}
        variant="outlined"
        component={FileInput}
        fullWidth
      />
    ),
    [INPUT_VARIANT.formatNumber]: (
      <NumberFormatInput
        name={field.name}
        label={field.label}
        variant="outlined"
        fullWidth
        formatprops={field.formatprops ? field.formatprops : {}}
      />
    ),
    [INPUT_VARIANT.select]: (
      <CustomSelect
        name={field.name}
        label={field.label ? field.label : ''}
        variant="outlined"
        id={`${field.name}-select`}
        data={field.data ? field.data : []}
        mapData={field.mapData}
        error={errors[field.name]}
        onChange={field.onChange}
        disabled={field.disabled}
        isStepForm={isStepForm}
      />
    ),
    [INPUT_VARIANT.multiSelectSearch]: (
      <CustomMultiSelectSearch
        name={field.name}
        label={field.label ? field.label : ''}
        variant="outlined"
        id="classes"
        defaultValues={field.defaultValues}
        data={field.data ? field.data : []}
        error={errors[field.name]}
        setFieldValue={setFieldValue}
        onChange={field.onChange}
        disabled={field.disabled}
        isStepForm={isStepForm}
        formValues={formValues}
      />
    ),
    [INPUT_VARIANT.country]: (
      <CountrySelect
        id={`${field.name}-select`}
        name={field.name}
        label={field.label ? field.label : ''}
        indicator={field.indicator}
        error={errors[field.name] ? errors[field.name] : ''}
        checkPeru={field.checkPeru}
        isStepForm
      />
    ),
    [INPUT_VARIANT.peruCounties]: (
      <PeruCountySelect
        id={`${field.name}-select`}
        name={field.name}
        label={field.label ? field.label : ''}
        indicator={field.indicator}
        error={errors[field.name] ? errors[field.name] : ''}
      />
    ),
    [INPUT_VARIANT.nationality]: (
      <NationalitySelect
        id={`${field.name}-select`}
        name={field.name}
        label={field.label ? field.label : ''}
        setFieldValue={setFieldValue}
        error={errors[field.name] ? errors[field.name] : ''}
        defaultValue={model ? model[field.name] : initialValues[field.name]}
      />
    ),
    [INPUT_VARIANT.radio]: (
      <RadioButtons
        id={`${field.name}-radios`}
        name={`${field.name}`}
        label={field.label ? field.label : ''}
        data={field.data ? field.data : []}
        mapData={field.mapData}
        onChange={field.onChange}
        vertical={field.vertical}
        error={errors[field.name] ? errors[field.name] : ''}
        disabled={field.disabled}
      />
    ),
    [INPUT_VARIANT.checkbox]: (
      <Checkboxes
        label={field.label}
        name={field.name}
        data={field.data ? field.data : []}
        mapData={field.mapData}
        other={!!field.other}
        otherFund={!!field.otherFund}
        error={errors[field.name] ? errors[field.name] : ''}
        setFieldValue={setFieldValue}
        selected={model ? model[field.name] : initialValues[field.name] || []}
      />
    ),
    [INPUT_VARIANT.singleCheckbox]: (
      <>
        <Checkbox
          name={field.name}
          error={errors[field.name] ? errors[field.name] : ''}
          setFieldValue={setFieldValue}
          checked={
            model ? model[field.name] : initialValues[field.name] || false
          }
        />
        <FormLabel component="label" color="primary">
          {field.label}
        </FormLabel>
      </>
    ),
    [INPUT_VARIANT.paragraph]: (
      <ClientTexts type={field.type} text={field.text} />
    ),
    [INPUT_VARIANT.inputBox]: (
      <InputBox
        name={field.name}
        text={field.label}
        variant="outlined"
        format={field.format ? field.format : {}}
      />
    ),
    [INPUT_VARIANT.sortable]: (
      <>
        {type == 'Sortable' && (
          <SortableContainer
            onSortEnd={({ oldIndex, newIndex }) => {
              setFieldValue(
                field.name,
                arrayMove(field.data, oldIndex, newIndex).map(a => a.value),
              );
            }}
          >
            {field.data &&
              mapOrder(
                field.data,
                formValues[field.name],
                'value',
              ).map((value, index) => (
                <SortableItem
                  key={value.value}
                  index={index}
                  value={value.label}
                />
              ))}
          </SortableContainer>
        )}
      </>
    ),
    [INPUT_VARIANT.node]: field.node,
    default: <></>,
  };
  return cases[type];
};

function FieldSelect(props) {
  const {
    field,
    errors,
    model,
    initialValues,
    setFieldValue,
    customOnChange,
    customOnChangeFields,
    isStepForm,
  } = props;
  const { values: formValues } = useFormikContext();

  useEffect(() => {}, [errors]);

  useEffect(
    () => {
      const results = customOnChange(formValues);
      Object.keys(results).forEach(field =>
        setFieldValue(field, results[field]),
      );
    },
    customOnChangeFields.map(field => formValues[field]),
  );

  return (
    <>
      {Array.isArray(field.gridMD) ? (
        <>
          {field.inputVariant === INPUT_VARIANT.places && field.indicators && (
            <PlacesSelects
              country={
                field.indicators.country
                  ? model[field.indicators.country.name]
                  : ''
              }
              county={
                field.indicators.county
                  ? model[field.indicators.county.name]
                  : ''
              }
              city={
                field.indicators.city ? model[field.indicators.city.name] : ''
              }
              district={
                field.indicators.district
                  ? model[field.indicators.district.name]
                  : ''
              }
              compact={field.compact ? field.compact : false}
              ubigeo={field.ubigeo ? field.ubigeo : 0}
              errors={
                field.indicators
                  ? {
                      country: field.indicators.country
                        ? errors[field.indicators.country.name]
                        : errors.country,
                      county: field.indicators.county
                        ? errors[field.indicators.county.name]
                        : errors.county,
                      city: field.indicators.city
                        ? errors[field.indicators.city.name]
                        : errors.city,
                      district: field.indicators.district
                        ? errors[field.indicators.district.name]
                        : errors.district,
                    }
                  : {
                      country: errors.country,
                      county: errors.county,
                      city: errors.city,
                      district: errors.district,
                    }
              }
              setFieldValue={setFieldValue}
              gridMD={field.gridMD}
              indicators={field.indicators}
              isStepForm={isStepForm}
            />
          )}
          {field.inputVariant === INPUT_VARIANT.otherSelect && (
            <SelectOtherInput
              name={field.name}
              label={field.label}
              disabled={field.disabled}
              nameText={field.nameText ? field.nameText : ''}
              labelText={field.labelText ? field.labelText : ''}
              id={`${field.nameSelect}-select`}
              data={field.data ? field.data : []}
              mapData={field.mapData}
              gridMD={field.gridMD}
              error={errors[field.name]}
              defaultOther={field.defaultOther}
            />
          )}
          {field.inputVariant === INPUT_VARIANT.conditionalSelects && (
            <ConditionalSelectsInput
              name={field.name}
              label={field.label}
              data={field.data ? field.data : []}
              mapData={field.mapData}
              gridMD={field.gridMD}
              items={field.items}
              errors={errors}
              initialValue={field.initialValue}
            />
          )}
          {field.inputVariant === INPUT_VARIANT.serviceTime && (
            <ServiceTimeInputs
              name={field.name}
              label={field.label}
              nameMonth={field.nameMonth ? field.nameMonth : ''}
              labelMonth={field.labelMonth ? field.labelMonth : ''}
              gridMD={field.gridMD}
            />
          )}
        </>
      ) : (
        <Grid
          key={field.name}
          item
          xl={field.gridXL || (isStepForm ? 12 : 6)}
          md={field.gridMD || 6}
          xs={field.gridXS || 12}
        >
          {Fields(field.inputVariant, {
            field,
            errors,
            initialValues,
            setFieldValue,
            model,
            isStepForm,
            formValues,
          })}
        </Grid>
      )}
    </>
  );
}
FieldSelect.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  field: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  model: PropTypes.shape(),
  setFieldValue: PropTypes.func.isRequired,
  customOnChange: PropTypes.func,
  customOnChangeFields: PropTypes.array,
  isStepForm: PropTypes.bool,
};
FieldSelect.defaultProps = {
  model: null,
  customOnChange: () => [],
  customOnChangeFields: [],
  isStepForm: false,
};

export default FieldSelect;
