/*
 * HomePage
 */

import React, { useState } from 'react';

// Material Resources

// Components & Others
// Styles & Images
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import Banners from './banners';
import SearchInput from './search';
import ModeIcon from './svg/modeIcon';
import MoreInfoIcon from './svg/helpIcon2';
import { useDispatch, useSelector } from 'react-redux';
import { setModeClient } from 'src/store/utils-store/actions';
import getClientMode from 'src/store/utils-store/selectors';

function InvestorCards(props) {
  const history = useHistory();
  const [mode, setMode] = useState(false);
  const { urlF2 } = props;
  const cards = [
    {
      title: 'Repositorios',
      cards: [{ title: 'Repositorio Personal', path: '/cliente/documentos' }],
      path: '',
    },
    {
      title: 'Documentos',
      cards: [
        { title: 'Ficha Cliente', path: '' },
        { title: 'Firmas Pendientes', path: '/cliente/firmas' },
        {
          title: 'Estado de Cuenta',
          path: '/cliente/estados-de-cuenta-de-inversiones',
        },
      ],
      path: '',
    },
  ];

  const modeClient = useSelector(getClientMode());
  const classes = useStyles();
  const dispatch = useDispatch();
  const handlerMode = state => {
    setMode(state);
    localStorage.setItem('mode', state);
    dispatch(setModeClient(state));
  };
  const cardStyle = modeClient ? classes.cardDark : classes.card;
  const nameStyle = modeClient ? classes.nameCardDark : classes.nameCard;
  const titleStyle = modeClient ? classes.titleDark : classes.title;

  return (
    <>
      <div className={classes.layout}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            gap: '10px',
            padding: '10px',
          }}
        >
          {/* <SearchInput /> */}
          <div
            style={{
              display: 'flex',
              width: 'fit',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <div
              role="button"
              tabIndex={0}
              style={{ cursor: 'pointer' }}
              onClick={() => handlerMode(!mode)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handlerMode(!mode);
                }
              }}
            >
              <ModeIcon />
            </div>

            {/* <MoreInfoIcon /> */}
          </div>
        </div>

        <Banners urlF2={urlF2} />

        <div className={classes.content}>
          {cards.map(item => {
            return (
              <>
                <div className={classes.sectionDiv}>
                  <p className={titleStyle}>{item.title}</p>
                  <div className={classes.cardsDiv}>
                    {item.cards.map(items => {
                      return (
                        <>
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              if (items.title === 'Ficha Cliente') {
                                window.location.href = urlF2;
                              } else {
                                history.push(items.path);
                              }
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                if (items.title === 'Ficha Cliente') {
                                  window.location.href = urlF2;
                                } else {
                                  history.push(items.path);
                                }
                              }
                            }}
                          >
                            <div className={cardStyle}>
                              <p className={nameStyle}>{items.title}</p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default InvestorCards;
