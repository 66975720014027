import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formControl: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.secondary.main,
    },
  },
  other: {
    display: 'flex',
  },
}));
