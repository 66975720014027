import React, { useState, useEffect, useRef } from 'react';

// Material Resources
import {
  FormControl,
  FormHelperText,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

// Others
import PropTypes from 'prop-types';
import { Select } from 'formik-material-ui';
import { TextField } from 'formik-material-ui';

// Styles & Images
import useStyles from './styles';

function Checkboxes(props) {
  // data : Array of elements, example: [{ country_name: "Peru", country_val: "PE" }]
  // mapData: Define which object property use as value and label, example: {value: "country_val", label: "country_name"}
  // mapData default => {value: "value", label: "label"}
  const {
    label,
    name,
    other,
    otherData,
    otherFund,
    otherFundData,
    data,
    mapData,
    error,
    selected,
    readOnly, // TODO_F: for save sign
    setFieldValue,
  } = props;
  const [values, setValues] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (selected.length >= 0) {
      setValues(selected);
    }
  }, [selected, name]);

  function handleChange(event) {
    const val = event.target.value;
    if (val === 'other') {
      if (!event.target.checked) {
        setFieldValue(otherData.name, '');
      }
    }
    if (val === 'otherFund') {
      if (!event.target.checked) {
        setFieldValue(otherFundData.name, '');
      }
    }
    const active = [...values];
    const find = active.findIndex(i => i === val);
    if (find === -1) {
      active.push(val);
    } else {
      active.splice(find, 1);
    }
    setValues(active);
    setFieldValue(name, active);
  }

  return (
    <FormControl
      error={error !== ''}
      component="fieldset"
      className={classes.formControl}
      fullWidth
    >
      {label !== '' && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup>
        {data.map(element => (
          <FormControlLabel
            key={element[mapData.value]}
            control={
              <Checkbox
                checked={values.some(val => element[mapData.value] === val)}
                color="secondary"
                value={element[mapData.value]}
                onChange={handleChange}
              />
            }
            label={element[mapData.label]}
          />
        ))}
        {other && (
          <div className={classes.other}>
            <Checkbox
              color="secondary"
              checked={values.some(val => val === 'other')}
              onChange={handleChange}
              value="other"
              inputProps={{
                'aria-label': 'other checkbox',
              }}
            />
            <TextField
              // If is needed for other use, put this as props
              disabled={!values.some(val => val === 'other')}
              name={otherData.name}
              label={otherData.label}
              variant="outlined"
              fullWidth
            />
          </div>
        )}
        {otherFund && (
          <div className={classes.other}>
            <Checkbox
              color="secondary"
              checked={values.some(val => val === 'otherFund')}
              onChange={handleChange}
              value="otherFund"
              inputProps={{
                'aria-label': 'other checkbox',
              }}
            />
            <TextField
              // If is needed for other use, put this as props
              disabled={!values.some(val => val === 'otherFund')}
              name={otherFundData.name}
              label={otherFundData.label}
              variant="outlined"
              fullWidth
            />
          </div>
        )}
        {error !== '' && <FormHelperText>{error}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
}
Checkboxes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  selected: PropTypes.array,
  other: PropTypes.bool,
  otherData: PropTypes.shape(),
  otherFund: PropTypes.bool,
  otherFundData: PropTypes.shape(),
};
Checkboxes.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  label: '',
  error: '',
  selected: [],
  other: false,
  otherData: {
    name: 'otherSafiRelation',
    label: 'Otro tipo de vinculación o relación',
  },
  otherFund: false,
  otherFundData: {
    name: 'otherFund',
    label: 'Otro fondo de inversión',
  },
};

export default Checkboxes;
