import React, { useState, useEffect } from 'react';

// Material Resources
import { TextField, FormHelperText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// GraphQl
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

// Others
import PropTypes from 'prop-types';

// Styles & Images
import useStyles from './styles';
import { edgeToList } from '../../../utils/commonFunctions';

const LIST_NATIONALITIES = gql`
  {
    nationalities {
      code
      name
    }
  }
`;

export const reorderNationalities = nationalityList => {
  const newList = [...nationalityList];
  const index = newList.findIndex(value => value.code === 'PE');

  if (index !== -1) {
    newList.unshift(newList.splice(index, 1)[0]);
  }
  return newList;
};

function NationalitySelect(props) {
  const { id, name, label, error, defaultValue, setFieldValue } = props;
  const [value, setValue] = useState([]);
  const classes = useStyles();

  const {
    error: nationalityError,
    data: nationalityData,
    loading: nationalityLoading,
  } = useQuery(LIST_NATIONALITIES);

  function handleChange(event, value) {
    event.preventDefault();
    const filterValue = value.filter(
      (v, i) => i === value.findIndex(n => n.code === v.code)
    );
    setValue(filterValue);
    const nationalities = filterValue.map(nat => {
      return nat.code;
    });
    setFieldValue(name, nationalities);
  }

  if (nationalityError) {
    console.error('nationalityError', nationalityError);
  }

  useEffect(() => {
    if (nationalityData && !nationalityLoading) {
      const initialValue = [];
      defaultValue.forEach(dv => {
        const nat = nationalityData.nationalities.find(
          item => item.code === dv
        );
        if (nat) {
          const { __typename, ...natVal } = nat;
          initialValue.push(natVal);
        }
      });
      setValue(initialValue);
    }
  }, [nationalityLoading, defaultValue]);

  return (
    <>
      <Autocomplete
        multiple
        loading={nationalityLoading}
        loadingText="Cargando ..."
        id={id}
        value={value}
        options={
          !nationalityLoading && nationalityData.nationalities
            ? reorderNationalities(nationalityData.nationalities).map(n => {
                const { __typename, ...nat } = n;
                return nat;
              })
            : []
        }
        getOptionLabel={option => option.name}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            name={name}
            error={error !== ''}
            placeholder="Nacionalidad"
            fullWidth
          />
        )}
      />
      {error !== '' && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
}
NationalitySelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.array,
};
NationalitySelect.defaultProps = {
  error: '',
  defaultValue: [],
};

export default NationalitySelect;
